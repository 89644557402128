
.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #99a0ad;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .bodypage
  {
    background-color: #000c0c;
    min-height: 92.5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .Headpage
  {
    background-color: #767e79f8;
    min-height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .footerStyle
  {
    background-color: #04792b;
    min-height: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
 .outputstyle_Name
 {
  font-size: calc(px + 2vmin);
  color: #09ca60; 
 }
 .outputstyle_Led
 {
  
    border-radius: 1px;
    width: 2px;
    height: 2px;
    box-shadow: 0px 0px 3px black;
    margin: 3px;
    zoom: 3;
 }
 .outputstyle_Led_OFF
 {
  background-color: #012210; 
 }
 .outputstyle_Led_ON
 {
  background-color: #09ca60; 
 }
 .ViewDataStyle
 {
  background-color: #6deb97;
  min-height: auto;
  max-width: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
 }
 .ViewDataStyle_col1
 {
  background-color: #010c04;
  border-bottom-style: solid;
  border-bottom-width: thin;
 }
 .ViewDataStyle_col2
 {
  background-color: #14311e;
  border-bottom-style: solid;
  border-bottom-width: thin;
 }
 ViewDat_container
 {
  background-color: lightblue;
  width: 20px;
  height:20px;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: auto;
 }
.WifiRefreshButton
{
 
  align-items: right;
  border: 10px solid green;
}